<router-outlet (activate)="registerPowerMagic($event)"></router-outlet>

<p-overlayPanel #op (onShow)="pp.focus()" (onHide)="pp.value = null">
	<p-panel header="? ? ? ? ? ?">
		<input pInputText type="password" #pp (input)="checkPP(pp.value)" />
	</p-panel>
</p-overlayPanel>

<p-toast position="top-right" [baseZIndex]="9000"></p-toast>

<!-- confirmdialog for global actions -->
<p-confirmDialog #cd [baseZIndex]="9000" [dismissableMask]="true" key="global"
	[closable]="true" defaultFocus='accept' header="Confirmation required" i18n-header class="custom-confirm-dialog">
	<ng-template pTemplate="header">
		<i class="p-dialog-titlebar p-dialog-titlebar-icon" class="mdi mdi-alert" style="color: coral;"></i>
		<h5>{{cd.header}}</h5>
		<button pRipple type="button" class="p-link" (click)="cd.reject()"
			(keydown.enter)="cd.reject()" i18n-title title="Close">
			<i class="mdi mdi-close"></i>
		</button>
	</ng-template>
	<ng-template pTemplate="footer" style="text-align: center;">
		<button pButton type="button" icon="pi pi-check" i18n-label label="Yes" (click)="cd.accept()"
			style="margin: 0 auto; display: block;"></button>
	</ng-template>
</p-confirmDialog>

<!-- confirmPopup for local actions -->
<p-confirmPopup defaultFocus="reject" key="local"></p-confirmPopup>

<button 
	pButton
	class="mdi mdi-chat-outline mdi-24px freescout"
	[class.hidden]="showFreescout"
	[rounded]="true"
	(click)="showFreescout = true"
></button>
@if (freescoutUrl) {
	<iframe
		id="fsw-iframe"
		class="freescout"
		[src]="freescoutUrl"
		[class.hidden]="!showFreescout"
	></iframe>
}
