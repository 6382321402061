import {
	enableProdMode,
	APP_INITIALIZER,
	importProvidersFrom,
} from '@angular/core';

import { Environment } from './environments/environment';
import { AppComponent } from './modules/app/component/app.component';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { AppRoutes } from './modules/app/app.routes';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { MessageService, ConfirmationService } from 'primeng/api';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	withInterceptorsFromDi,
	provideHttpClient,
} from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { keycloakServiceFactory } from './modules/app/auth/keycloak-service.factory';
import { LocalStorageService, provideNgxWebstorage, withLocalStorage } from 'ngx-webstorage';
import { Menu } from './modules/app/menu/menu';
import {
	AppMenu,
	HttpInterceptor,
	BASE_BACKEND_URL,
	GraphQLClient,
} from '@biorec/common';
import { KeycloakService, KeycloakBearerInterceptor } from 'keycloak-angular';

/// <reference types="@angular/localize" />

if (Environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			BrowserModule,
			TooltipModule,
			BadgeModule,
			ToastModule,
			ConfirmDialogModule,
			ConfirmPopupModule,
			OverlayPanelModule,
			PanelModule,
			InputTextModule,
			AppRoutes,
			MonacoEditorModule.forRoot()
		),
		provideNgxWebstorage(
			withLocalStorage()
		),
		KeycloakService,
		{ provide: AppMenu, useClass: Menu },
		{
			provide: APP_INITIALIZER,
			deps: [KeycloakService, LocalStorageService],
			useFactory: keycloakServiceFactory,
			multi: true,
		},
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: KeycloakBearerInterceptor,
			multi: true,
		},
		{ provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true },
		{ provide: BASE_BACKEND_URL, useFactory: () => {
			if(!Environment.backendUrl) throw "Please, add 'backendUrl' string to your environment.ts file!";
			return Environment.backendUrl;
		}},
		MessageService,
		ConfirmationService,
		HttpClient,
		GraphQLClient,
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimationsAsync(),
	],
}).catch((err) => console.error(err));
