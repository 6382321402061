import { Routes, RouterModule } from '@angular/router';

import { AppMainComponent, getContextProvider } from '@biorec/common';
import { AppContainerComponent } from './container/app-container.component';
import { authGuardFn, checkPermissionFn } from './auth/auth.guardfn';

export const routes: Routes = [
	{
		path: '', canActivate: [ authGuardFn ], component: AppMainComponent, children: [
			{
				path: '', component: AppContainerComponent, children: [
					{ path: '', loadChildren: () => import("../dashboard").then(m => m.DashboardRoutes) },
					{ path: 'dashboard', canActivate: [checkPermissionFn], data: {permission: 'menu.dashboard'}, loadChildren: () => import("../dashboard").then(m => m.DashboardRoutes), providers: [getContextProvider('dashboard', true)]},
					{ path: 'patients', canActivate: [checkPermissionFn], data: {permission: 'menu.patients'}, loadChildren: () => import("../patients").then(m => m.PatientsRoutes), providers: [getContextProvider('patients', true)]},
					{ path: 'doctors', canActivate: [checkPermissionFn], data: {permission: 'menu.doctors'}, loadChildren: () => import("../doctors").then(m => m.DoctorsRoutes), providers: [getContextProvider('doctors', true)]},
					{ path: 'exams', canActivate: [checkPermissionFn], data: {permission: 'menu.exams'}, loadChildren: () => import("../exams").then(m => m.ExamsRoutes), providers: [getContextProvider('exams', true)]},
					{ path: 'reports', canActivate: [checkPermissionFn], data: {permission: 'menu.reports'}, loadChildren: () => import("../reports").then(m => m.ReportsRoutes), providers: [getContextProvider('reports', true)]},
					{ path: 'inventory', canActivate: [checkPermissionFn], data: {permission: 'menu.inventory'}, loadChildren: () => import("../inventory").then(m => m.InventoryRoutes), providers: [getContextProvider('inventory', true)]},
					{ path: 'messages', canActivate: [checkPermissionFn], data: {permission: 'menu.messages'}, loadChildren: () => import("../messages").then(m => m.MessagesRoutes), providers: [getContextProvider('messages', true)]},
					{ path: 'merger', canActivate: [checkPermissionFn], data: {permission: 'menu.merger'}, loadChildren: () => import("../merger").then(m => m.MergerRoutes), providers: [getContextProvider('merger', true)]},
					{ path: 'invoices', canActivate: [checkPermissionFn], data: {permission: 'menu.invoices'}, loadChildren: () => import("../invoices").then(m => m.InvoicesRoutes), providers: [getContextProvider('invoices', true)]},
					{ path: 'receipts', canActivate: [checkPermissionFn], data: {permission: 'menu.receipts'}, loadChildren: () => import("../receipts").then(m => m.ReceiptsRoutes), providers: [getContextProvider('receipts', true)]},

					{
						path: 'admin', canActivate: [checkPermissionFn], data: {permission: 'menu.admin'}, children: [
							{ path: 'exams-groups', canActivate: [checkPermissionFn], data: {permission: 'menu.admin'}, loadChildren: () => import("../exams-groups").then(m => m.ExamsGroupsRoutes), providers: [getContextProvider('exams-groups', true)]},
							{ path: 'receipts-groups', canActivate: [checkPermissionFn], data: {permission: 'menu.admin'}, loadChildren: () => import("../receipts-groups").then(m => m.ReceiptsGroupsRoutes), providers: [getContextProvider('receipts-groups', true)]},
							{ path: 'receipts-templates', canActivate: [checkPermissionFn], data: {permission: 'menu.admin'}, loadChildren: () => import("../receipts-templates").then(m => m.ReceiptsTemplatesRoutes), providers: [getContextProvider('receipts-templates', true)]},
							{ path: 'images', canActivate: [checkPermissionFn], data: {permission: 'menu.admin'}, loadChildren: () => import("../images-groups").then(m => m.ImagesGroupsRoutes), providers: [getContextProvider('images', true)]},
							// { path: 'invoices-templates', canActivate: [checkPermissionFn], data: {permission: 'menu.admin'}, loadChildren: () => import("../invoices-templates").then(m => m.InvoicesTemplatesWrappedRoutes), providers: [getContextProvider('invoices-templates', true)]},
							{ path: 'tags', canActivate: [checkPermissionFn], data: {permission: 'menu.admin'}, loadChildren: () => import("../tags").then(m => m.TagsRoutes), providers: [getContextProvider('tags', true)]},
							{ path: 'clinics', canActivate: [checkPermissionFn], data: {permission: 'menu.admin'}, loadChildren: () => import("../clinics").then(m => m.ClinicsRoutes), providers: [getContextProvider('clinics', true)]},
							{ path: 'examiners', canActivate: [checkPermissionFn], data: {permission: 'menu.admin'}, loadChildren: () => import("../examiners").then(m => m.ExaminersRoutes), providers: [getContextProvider('examiners', true)]},
							{ path: 'patterns', canActivate: [checkPermissionFn], data: {permission: 'menu.admin'}, loadChildren: () => import("../patterns").then(m => m.PatternsRoutes), providers: [getContextProvider('patterns', true)]},
						]
					}
				],
			},
		]
	},

	{ path: '**', redirectTo: '/notfound' }
];

export const AppRoutes = RouterModule.forRoot(routes, {
	scrollPositionRestoration: 'enabled',
	anchorScrolling: 'enabled',
	onSameUrlNavigation: 'reload',
	paramsInheritanceStrategy: 'always',
	useHash: false
});

