import { MenuInterface } from '@biorec/common';
import { MenuItem } from 'primeng/api/menuitem';
import { Injectable } from '@angular/core';
import { PermissionsChecker } from '../auth/permissionChecker';

@Injectable()
export class Menu implements MenuInterface {

	public constructor(private permissions: PermissionsChecker) {
	}

	private allowedItems = [{}];

	private menuItems: MenuItem[] = [
		{ id: 'dashboard', label: $localize`Dashboard`, icon: 'pi pi-compass', routerLink: ['/'] },

		{ id: 'patients', label: $localize`Patients`, icon: 'pi pi-users', routerLink: ['/patients'] },
		{ id: 'doctors', label: $localize`Doctors`, icon: 'pi pi-briefcase', routerLink: ['/doctors'] },
		{ id: 'exams', label: $localize`Exams`, icon: 'pi pi-list', routerLink: ['/exams'] },
		{ id: 'reports', label: $localize`Reports`, icon: 'pi pi-file-excel', routerLink: ['/reports'] },
		{ id: 'messages', label: $localize`Messages`, icon: 'pi pi-comments', routerLink: ['messages'] },
		{ id: 'receipts', label: $localize`Receipts`, icon: 'pi pi-ticket', routerLink: ['/receipts'] },
		{ id: 'invoices', label: $localize`Invoices`, icon: 'pi pi-percentage', routerLink: ['/invoices'] },
		{ id: 'inventory', label: $localize`Inventory`, icon: 'mdi mdi-paperclip', routerLink:['/inventory'] },
		{ id: 'merger', label: $localize`Merger`, icon: 'pi pi-share-alt ', items: [
			{ label: $localize`Patients`, icon: 'pi pi-users', routerLink: ['merger', 'patients'] },
			{ label: $localize`Doctors`, icon: 'pi pi-briefcase', routerLink: ['merger', 'doctors'] }
		]},

		{ id: 'admin', label: $localize`Admin area`, icon: 'pi pi-cog', items: [
			{ label: $localize`Exams groups`, icon: 'pi pi-clone', routerLink: ['/admin', 'exams-groups'] },
			{ label: $localize`Images groups`, icon: 'pi pi-images', items: [
				{ label: $localize`Exams`, icon: 'pi pi-calendar-plus',  routerLink: ['admin', 'images', 'exams']  },
				{ label: $localize`Logos`, icon: 'pi pi-apple', routerLink: ['admin', 'images', 'logos'] },
				{ label: $localize`Unique`, icon: 'pi pi-exclamation-circle', routerLink: ['admin', 'images', 'unique'] }
			]},
			{ label:  $localize`Receipt`, icon: 'pi pi-inbox',  items: [
				{ label:  $localize`Groups`, icon: 'pi pi-clone', routerLink: ['/admin', 'receipts-groups'] },
				{ label:  $localize`Templates`, icon: 'pi pi-file-o',  routerLink: ['/admin', 'receipts-templates'] },
			]},
			{ label: $localize`Tags`, icon: 'pi pi-tags', routerLink: ['/admin', 'tags'] },
			{ label: $localize`Clinics`, icon: 'pi pi-home', routerLink: ['/admin', 'clinics'] },
			{ label: $localize`Examiners`, icon: 'pi pi-briefcase', routerLink: ['/admin', 'examiners'] },
			{ label: $localize`Users`, icon: 'pi pi-user-edit', routerLink: ['/admin', 'users'] },
			{ label: $localize`Patterns`, icon: 'pi pi-th-large', routerLink: ['/admin', 'patterns'] }
		]}
	];

	getMenu(): MenuItem[] {
		this.menuItems.forEach( a => {
			if (this.permissions.checkPermission('menu.' + a.id)) {
				this.allowedItems.push(a);
			}
		})
		return (this.allowedItems);
	}
}
