import { LOCALE_ID, isDevMode, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { PermissionsChecker } from './permissionChecker';
import { Environment } from '@biorec/admin/environment';
import { isNullOrUndefined } from 'src/admin/shared';

export function authGuardFn(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
	const router = inject(Router);
	const kcSvc = inject(KeycloakService);
	const locale = inject(LOCALE_ID);

	const guard = new Guard(router, kcSvc, locale)
	return guard.canActivate(route, state);
}

export function checkPermissionFn(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
	const checker = inject(PermissionsChecker);
	return checker.checkPermission(route.data.permission);
}

class Guard extends KeycloakAuthGuard {

	constructor(router: Router,kcSvc: KeycloakService, private locale: string) {
		super(router, kcSvc);
	}

	public async isAccessAllowed(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Promise<boolean | UrlTree> {

		// Force the user to log in if currently unauthenticated.
		if (!this.authenticated) {
			await this.keycloakAngular.login({ locale: this.locale, idpHint: !isNullOrUndefined(Environment.production) ? 'google-workspace' : null });
		}
		// check user locale
		const token = this.keycloakAngular.getKeycloakInstance().tokenParsed;
		if (token.locale && token.locale != this.locale) {
			console.warn(`You are using '${this.locale}' locale while user locale is set to '${token.locale}'`);
			if (!isDevMode()) {

				// dummy method to redirect to user chosen locale
				// TODO: find better way - hours lost = 4
				document.location.href = document.location.href
					.replace(new RegExp(`/${this.locale}/?`), `/${token.locale}/`);
			}
		}

		// Get the roles required from the route.
		const requiredRoles = route.data.roles;

		// Allow the user to proceed if no additional roles are required to access the route.
		if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
			return true;
		}

		// Allow the user to proceed if all the required roles are present.
		return requiredRoles.every((role) => this.roles.includes(role));
	}
}
