import { Component, LOCALE_ID, OnInit, inject } from '@angular/core';
import { AppMainComponent } from '@biorec/common';
import { KeycloakService } from 'keycloak-angular';
import { ButtonModule } from 'primeng/button';
import { NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';
import { Environment } from '@biorec/admin/environment';

@Component({
	selector: 'app-topbar',
	templateUrl: './app-topbar.component.html',
	styleUrls: ['./app-topbar.component.scss'],
	standalone: true,
	imports: [RouterLink, NgClass, ButtonModule],
})
export class AppTopBarComponent implements OnInit {
	activeItem: number | null;
	protected powered: boolean;
	protected user: Keycloak.KeycloakProfile;
	
	protected lang: any;
	protected langs = Environment.langs;

	constructor(
		public appMain: AppMainComponent,
		private kcSvc: KeycloakService
	) {
		this.setLanguage(inject(LOCALE_ID));
	}

	ngOnInit(): void {
		this.kcSvc.loadUserProfile().then((profile) => this.user = profile);
		this.powered = this.kcSvc.isUserInRole('powered');
	}
	
	protected setLanguage(locale: string) {
		if (!this.lang) {
			this.lang = this.langs.find(l => l.value == locale);
		} else {
			document.location.href = document.location.href
			.replace(new RegExp(`/${this.lang.value}/?`), `/${locale}/`);
		}
	}

	protected logout(event: Event) {
		event.preventDefault();
		event.stopPropagation();
		this.kcSvc.logout();
	}
}
