import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { LocalStorageService } from "ngx-webstorage";
import { Environment } from '../../../environments/environment';
import { filter } from "rxjs";

export function keycloakServiceFactory(kcs: KeycloakService, storage: LocalStorageService) {
	return () => {
		
		if (!Environment.keycloakOptions) {
			throw "Please, add 'keycloakOptions' object in your environment.ts";
		}

		const scopes = Environment.keycloakOptions.initOptions.scope 
			? Environment.keycloakOptions.initOptions.scope.split(' ') : [];
		storage.retrieve('last_state') && scopes.push(storage.retrieve('last_state'));
		Environment.keycloakOptions.initOptions.scope = scopes.join(' ');
		
		kcs.keycloakEvents$
			.pipe(filter(e => e.type == KeycloakEventType.OnAuthSuccess))
			.subscribe(() => {
				// process power mode
				kcs.isUserInRole('powered') ?
					storage.store('last_state', 'powered') : storage.clear('last_state');
				// set clinic cache
				if (!storage.retrieve('clinic')) {
					storage.store('clinic', kcs.getKeycloakInstance().tokenParsed.clinic);
				}
			});
		return kcs.init(Environment.keycloakOptions);
	}
}
