import { Component, HostListener, LOCALE_ID, OnInit, ViewChild, inject } from '@angular/core';
import { AppMainComponent } from '@biorec/common';
import { KeycloakService } from 'keycloak-angular';
import { LocalStorageService } from 'ngx-webstorage';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RouterOutlet } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	standalone: true,
	imports: [
		RouterOutlet,
		OverlayPanelModule,
		PanelModule,
		InputTextModule,
		ToastModule,
		ConfirmDialogModule,
		SharedModule,
		ButtonModule,
		ConfirmPopupModule,
	],
})
export class AppComponent implements OnInit {

	protected freescoutUrl: SafeUrl;
	protected showFreescout: boolean;
	protected pwdRequired: boolean;
	private powerManager = {
		timer: null as any,
		count: 0,
	};

	@ViewChild('op')
	private op: any;
	
	@HostListener('window:message', ['$event'])
	protected onMessage(event: MessageEvent) {
		if (event.data == 'fsw.minimize') {
			this.showFreescout = false
		};
	};
	
	private readonly sanitizer = inject(DomSanitizer);
	protected readonly locale = inject(LOCALE_ID);
	protected readonly kcSvc = inject(KeycloakService);
	private readonly storage = inject(LocalStorageService);

	ngOnInit(): void {
		this.kcSvc.loadUserProfile().then(profile => {
			this.freescoutUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
				'https://support.biorec.org/help/widget/form/3167901442?'
				+ 'cf[0]=2&cf[1]=3'
				+ '&locale=' + this.locale || this.kcSvc.getKeycloakInstance().tokenParsed.locale
				+ '&f[email]=' + encodeURIComponent(this.kcSvc.getUsername())
				+ '&f[name]=' + encodeURIComponent(`${profile.lastName} ${profile.firstName}`)
				+ '&f[' + encodeURIComponent('cf_3') + ']=' + (this.kcSvc.getKeycloakInstance().tokenParsed.clinic || '1') 
				+ '&f[' + encodeURIComponent('cf_2') + ']=9' //general category
			);
		});
	}

	protected registerPowerMagic(cmp: AppMainComponent) {
		cmp.onLogoClick.subscribe(this.managePower.bind(this));
	}

	private managePower(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();
		if (this.powerManager.timer) {
			clearTimeout(this.powerManager.timer);
		}
		if (this.powerManager.count == 2) {
			this.powerManager.count = 0;
			this.op ? this.op.show(event) : null;
		} else {
			this.powerManager.count++;
			this.powerManager.timer = setTimeout(() => {
				this.powerManager.count = 0;
				this.storage.clear('last_state');
				this.kcSvc.login();
			}, 300);
		}
	}

	protected checkPP(value: string) {
		if (value == this.kcSvc.getKeycloakInstance().tokenParsed.dummyAttr) {
			this.kcSvc.login({ scope: 'powered' });
		}
	}
}
