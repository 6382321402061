<div class="layout-topbar">
	<div class="layout-topbar-wrapper">
		<div class="layout-topbar-left">
			<div class="layout-topbar-logo-wrapper">
				<a
					class="layout-topbar-logo"
					[class.light]="!appMain.darkMode"
					[class.powered]="powered"
					(click)="appMain.onLogoClick.next($event)">&nbsp;</a>
			</div>
			<a href="#" class="sidebar-menu-button" (click)="appMain.onMenuButtonClick($event)">
				<i class="mdi mdi-menu"></i>
			</a>
			<a href="#" class="topbar-menu-mobile-button" (click)="appMain.onTopbarMobileMenuButtonClick($event)">
				<i class="mdi mdi-dots-vertical"></i>
			</a>
		</div>
		<div class="layout-topbar-right fadeInDown">
			<ul class="layout-topbar-actions">
				@if (user) {
					<li #profile class="topbar-item profile-item"
						[ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === profile}">
						<a href="#" (click)="appMain.onTopbarItemClick($event,profile)">
							<span class="profile-image-wrapper">
								<!-- img [src]="user.logo" class="avatar" alt="mirage-layout" /-->
								<i class="mdi mdi-48px mdi-account-circle"></i>
							</span>
							<span class="profile-info-wrapper">
								<h3>{{ user.lastName }} {{ user.firstName }}</h3>
							</span>
						</a>
						<ul class="profile-item-submenu fadeInDown">
							<li class="submenu-actions">
								<button pButton (click)="appMain.configActive = true" label="Settings" i18n-label></button>
								<button pButton (click)="logout($event)" label="Sign Out" i18n-label></button>
							</li>
						</ul>
					</li>
					<li #language class="topbar-item language-switch"
						[ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === language}">
						<a href="#" (click)="appMain.onTopbarItemClick($event,language)">
							<img [src]="lang?.flag" width="24" height="16"/>
						</a>
						<ul class="language-item-submenu fadeInDown">
							@for (l of langs; track l) {
								<li class="submenu-actions" (click)="setLanguage(l.value)">
									<img [src]="l?.flag" width="16" />
									{{ l.label }}
								</li>
							}
						</ul>
					</li>
				}
			</ul>
		</div>
	</div>
</div>
