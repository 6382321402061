import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppTopBarComponent } from '../topbar/app-topbar.component';

@Component({
    selector: 'app-container',
    templateUrl: './app-container.component.html',
    standalone: true,
    imports: [AppTopBarComponent, RouterOutlet],
})
export class AppContainerComponent {

}
